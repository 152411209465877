.App {
  text-align: left;
  width: 100%;
}
img {
  width: 100%;
}
body {  
  font-family: 'Inter', sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.active {
  color: white;
  background-color: #00c657;
}
/* .inactive {
  color: black;
  background-color: white;
} */
button:disabled {
  opacity: 0.3;
}
.default-checkbox:checked {
  background-color: #014f86;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
input:-internal-autofill-selected {
  background-color: transparent;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0px;
}
.loginBoxShadow {
  background: #ffffff;
  /* shadow/md */

  box-shadow: 0px 4px 8px -2px rgba(16, 40, 36, 0.1),
    0px 2px 4px -2px rgba(16, 40, 36, 0.06);
}
.searchBoxShadow {
  box-shadow: 0px 1px 3px rgba(16, 40, 36, 0.1),
    0px 1px 2px rgba(16, 40, 36, 0.06);
}

.tableBoxShdow {
  background: #ffffff;
  /* shadow/md */

  box-shadow: 0px 4px 8px -2px rgba(16, 40, 36, 0.1),
    0px 2px 4px -2px rgba(16, 40, 36, 0.06);
  border-radius: 8px;
}
.header {
  grid-area: header;
  background: #ffffff;
  /* gray/300 */

  border-bottom: 1px solid #d1d5db;
}
.sidebar {
  grid-area: sidebar;
  background-color: #0f5e91;
  position: relative;
  /* border-right: 1px solid #e5e7eb; */
  /* margin-top: 15px !important; */
}
.content {
  grid-area: content;
}
.g-container {
  overflow-x: hidden;
  height: 100vh;
  grid-template-rows: 15px auto;
  grid-template-columns: 250px auto;
  background-color: #0f5e91;
  grid-template-areas:
    'header  header '
    'sidebar content ';
}

.g-container.inactive {
  overflow-x: hidden;
  height: 100vh;
  grid-template-rows: 15px auto;
  grid-template-columns: 90px auto;
  background-color: #0f5e91;
  grid-template-areas:
    'header  header '
    'sidebar content ';
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-previewed,
input:-internal-autofill-selected,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.pac-container {
  z-index: 1500;
}
.main-container {
  border-top-left-radius: 60px;
  padding: 30px;
}
.sidebar-layout > .grid {
  padding-left: 20px;
  padding-right: 20px;
}
.navbar {
  padding: 20px 0 0 70px;
  cursor: pointer;
}
.navbar.inactive {
  padding: 20px 0 0 5px;
  cursor: pointer;
}
.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: #000;
}
.nav-menu {
  background-color: #ffffff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 450ms;
}
.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  margin: 20px auto;
  padding: 10px;
  background-color: #f2f2f2;
}

.filter-input {
  width: 100%;
  border: none;
  padding: 10px;
}

.filter-icon {
  font-size: 24px;
  color: #aaa;
}

.userWel {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #333333;
}
.userWelText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b0b0b0;
}

.t-container {
  height: 100vh;
  grid-template-rows: 15px auto;
  grid-template-columns: 250px auto;
}

.topbar {
  width: 100%;
  float: left;
  padding: 15px 30px;
  border-bottom: 1px solid #d1d5db;
}
.logotop {
  float: left;
  width: 40%;
}
.logotop .logo {
  width: 200px;
}
.topbarRight {
  float: right;
  width: 60%;
  text-align: right;
  display: block ruby;
}
.topbarBell {
  width: 100px;
  float: left;
  text-align: center;
  padding-top: 8px;
}
.topbarBell .TableBellIcon_tableBell__ly0cY img {
  width: 20px;
}
.topbarUser{
  float: right;
  cursor: pointer;
}
.topbarUserimg {
  width: 40px;
  float: left;
  text-align: center;
}
.topbarUserimg img {
  width: 40px;
  float: left;
  border-radius: 50%;
  height: 40px;
  object-fit: fill;
}
.topbarDownArrow {
  width: 40px;
  float: left;
  text-align: center;
  padding-top: 8px;
}
.topbarDownArrow img {
  width: 12px;
}
.topbarUserName {
  float: left;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  padding-top: 8px;
}
.main-t-container {
  width: 100%;
  float: left;
  padding: 15px 30px;
}
.lightBtn {
  background-color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
  padding: 10px 16px;
  text-transform: none;
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin: 0px 10px;
}
.lightBtn:hover {
  background-color: #f0f2f4;
  border: 1px solid #d0d5dd;
}
.mainBtn {
  background-color: #0f5e91;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding: 10px 16px;
  border: 1px solid #0c4b74;
  border-radius: 8px;
  text-transform: none;
  height: 44px;
  margin: 0px 10px;
}
.mainBtn:hover {
  background-color: #0f5e91;
}
.warningBtn {
  background-color: #f0ad4e ;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding: 10px 16px;
  border: 1px solid #eb9114;
  border-radius: 8px;
  text-transform: none;
  height: 44px;
  margin: 0px 10px;
}
.warningBtn:hover {
  background-color: #f0ad4e;
}
.fullBtn {
  width: 100%;
  margin: 0;
}
.medemBtnSize{
  padding: 0px 85px;
}
.gray-container {
  width: 100%;
  float: left;
  background: rgba(245, 245, 245, 0.1);
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.container-gray-border {
  background: rgba(245, 245, 245, 0.1);
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.white-container {
  width: 100%;
  float: left;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.container-white-border {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.blanck-container {
  width: 100%;
  float: left;
  padding: 20px 0px;
  margin-bottom: 20px;
}
.itemsRight {
  text-align: right;
}
.itemsLeft {
  text-align: left;
}
.itemsCenter {
  text-align: center;
}
.container-heading {
  color: #333333;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.cont20 {
  float: left;
  width: 20%;
}
.cont25 {
  float: left;
  width: 25%;
}
.cont33 {
  float: left;
  width: 33.333%;
}
.cont50 {
  float: left;
  width: 50%;
}
.cont75 {
  float: left;
  width: 75%;
}
.cont100 {
  float: left;
  width: 100%;
}
.font14 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.font12 {
  font-size: 12px;
  line-height: 20px;
}
.gray70 {
  color: #545454;
}
.plr25 {
  padding-right: 25px;
  padding-left: 25px;
}
.mb20 {
  margin-bottom: 30px;
}
.mb50 {
  margin-bottom: 50px;
}
.clearFix {
  clear: both;
 
}
.userImg {
  width: 40px;
  border-radius: 50%;
}
.userName {
  color: #333333;
  text-decoration: underline;
  text-underline-offset: 7px;
  padding-left: 10px;
}
.MuiInputBase-input {
  padding: 10px 20px;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 8px;
}
.validationError {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-bottom: 0;
  margin-left: 14px;
}

.upMenu{ 
  position: absolute;
  bottom: 0px;
  color: #fff;
  padding-bottom: 40px;
  width: 100%;
  
}
.sidebarUser{
  display: flex;
  border-top: 1px solid #fff;
padding-top: 20px;
}
.sidebarUserImg{
  padding-left: 12px;
}
.sidebarUserImg img{
  width: 40px;
border-radius: 50%;
height: 40px;
object-fit: fill;
}
.sidebarUserName{
  padding-left: 10px;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;
padding-top: 7px;
overflow: hidden;
}
.sidebarUserLinkE{
  font-weight: 400;
  font-size: 11px;
  color: #E9D7FE;
}

.sidebarUserLinkN{
  color: #ffffff;
}
.sidebarUserlogout{
  text-align: right;
  float: right;
  flex: fit-content;
  padding-right: 12px;
  padding-top: 10px;
  cursor: pointer;
}
.sidebarUserlogoutMenu{
  text-align: center;
  padding-bottom: 20px;
  cursor: pointer;
}
