.pageMenu{
  border-bottom: 1px solid #E3E3E3;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  
}
.pageMenuName{
  padding: 10px 25px;
  cursor: pointer;
}

.pageMenu .tabactive{
  color: #0F5E91;
  border-bottom: 2px solid #0F5E91;
}
.profileDIV{
  border-bottom: 1px solid #E3E3E3;
  display: flex;
  padding-bottom: 50px;
  margin-bottom: 30px;
}
.pimgCant{
  padding-left: 20px;
  position: relative; 
}
.pimgDIv{
  padding-left: 0px;
  display: block;
}
.pimgDIv .puserImg{
  width: 160px;
  border-radius: 50%;
  height: 160px;
  object-fit: fill;
}
.cimgDIv{
  background-color: #fff;
padding: 8px;
text-align: center;
position: absolute;
bottom: 10px;
right: 10px;
border-radius: 50%;
cursor: pointer;
}
.cameraImg{
  width: 20px;
}
.pNameDiv{
  padding-left: 40px;
  padding-top: 40px;
}

.pUserName{
  font-weight: 500;
font-size: 30px;
line-height: 38px;
color: #06263A;
}
.pUserEmail{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
  margin-bottom: 15px;
  
}
.pUserBtn{
  background: #FFFFFF;
box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
border-radius: 16px;
text-align: center;
width: 125px;
font-size: 12px;
color: #545454;
padding: 10px 0px;
cursor: pointer;
}
.textRight{
  text-align: right;
}

.notiCant{
  display: flex;
  padding: 5px 10px;
border-bottom: 1px solid #F5F5F5;
margin-bottom: 15px;
}
.notiImg{
  padding: 10px;
}
.notiUserImg{
  width: 45px;
  border-radius: 50%;
  height: 45px;
}
.notiText{
  padding: 0px 20px;
  cursor: pointer;
}
.notitit{font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;  
}
.notibody{
  font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #757575;
}
.notitime{
  font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #545454;
}
.notiActi{
  text-align: right;
float: right;
flex: fit-content;
}
.notiActImg{
  width: 10px;
  cursor: pointer;
}

.field-container {
  width: 100%;
  /* float: left; */
  background: #ffffff;
  /* border: 1px solid #e3e3e3; */
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.divider{
  border:1px solid #E3E3E3;
  height: 0px ;
  width: 96%;
  margin-left:20px
}
.heading-typography{
  font-size:14px;
  color:#333333;
  font-weight: 500;
  /* font-family: Inter; */
}