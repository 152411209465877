.listingPhoto{
  width: 100%;
  float: left;
}
.imgTabName{
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px;
}
.imgCant{
  width: 100%;
  float: left;
  background-color: #fff; 
  height: 150px;  
  align-items: center;
  overflow:hidden;
  border-radius: 8px;
}
.imgCant .fileImg{
  min-width:100%;
}
.imgCheckbox{
  
  float: left;
  color: #fff;
  background-color: #FFC200;
  border-radius: 8px;
  opacity: 0.8;
  margin: 5px;
  padding-right: 3px;
}

.imgDelete{
  float: right;
  text-align: center;
  padding: 5px;
  background-color: #FFC200;
  border-radius: 8px;
  opacity: 0.8;
  margin: 5px;
  cursor: pointer;
}
.deleteImg{
  width: 20px; 
}
.imgCantBo{
  width: 100%;
  float: left;
  background-color: #fff; 
  height: 124px;
  display: flex; 
  align-items: center;
  border: 1px solid #EAECF0;
  border-radius: 8px;
}
.imgCantBo span{
  width: 100%;
}
.fileIcon{
  width: 100%;
  float: left;
  text-align: center;
  font-weight: 400;
font-size: 14px;
}
.fileIcon .fileImg{
  width: 50px;
}
.fileIconText{
  line-height: 40px;
  color: #0F5E91;
  font-weight: 500;
font-size: 12px;
}

.docDiv{
  width: 100%;
  float: left;
}
.docName{
  font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #333333;
margin-bottom: 8px;
}
.docCont{
  width: 100%;
  float: left;
  padding: 25px 12px 25px 12px;
background: #FFFFFF;
border: 1px solid #BBDBF0;
border-radius: 8px;
}
.docIcon{
  width: 15%;
  float: left;
}
.docFileImg{
  width: 40px;
}
.docText{
  width: 75%;
  float: left;
  padding-left: 10px;
  cursor: pointer;
}
.docTextN{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  text-transform: lowercase;
}
.docTextN:first-letter {
  text-transform: capitalize;
}
.docTextSi{
  font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #757575;
}
.docTextSt{  
  float: left;
  margin-top: 10px;
}
.docTextSt .Approved{
  padding: 2px 12px 3px 10px;
  background: #ECFDF3;
  border-radius: 16px;
  font-weight: 500;
font-size: 12px;
line-height: 18px;
text-align: center;
color: #027A48;
}
.docTextSt .Reject{
  padding: 2px 12px 3px 10px;
  background: #fed5cd;
  border-radius: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #DC2804;
}
.docTextSt .Pending{
  padding: 2px 12px 3px 10px;
  background: #EAECF0;
  border-radius: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #757575;
}
.docTextSt .ChangePending{
  padding: 2px 12px 3px 10px;
  background: #fff9e6;
  border-radius: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #CC9B00;
}
.docActionIcon{
  width: 10%;
  float: left;
  text-align: right;
  cursor: pointer;
}
.docDotImg{
  width: 4px;
}