
.tableBell{
  
}

.tableBell img{
  width: 18px;
}

.notifCant{
  width: 100%;
  float: left;
  padding: 10px 5px;
  border-bottom: 1px solid #F5F5F5;
}

.notifImg{
  width: 15%;
  float: left;
  text-align: center;
  cursor: pointer;
}
.notifImg img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.notifTxt{
  width: 75%;
  float: left;
  padding-left: 10px;
  cursor: pointer;
}
.notifCnl{
  width: 10%;
  float: left;
  text-align: center;
}
.notifCnl img{
  width: 10px;
  cursor: pointer;
}
.bellIconImg{ 
  cursor: pointer;
}
.notifTxtEmil{
  width: 100%;
  float: left;
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
.notifTxtSub{
  width: 100%;
  float: left;
  padding-bottom: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #757575;
  max-height: 40px;
  overflow: hidden;
}
.notifTxtTime{
  width: 100%;
  float: left;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #545454;
}
.notiCant{
  position: relative;
}
.notiCount{
  background-color: #DC2804;
padding: 0px 6px 0px 6px;
text-align: center;
position: absolute;
bottom: 10px;
right: 23px;
border-radius: 12px;
color: #FFF;
font-size: 11px;
font-weight: 300;
opacity: 0.9;
cursor: pointer;
}
.notiCountTable{
  background-color: #DC2804;
  padding: 0px 6px 0px 6px;
  text-align: center;
  position: absolute;
  bottom: 12px;
  left: 12px;
  border-radius: 12px;
  color: #FFF;
  font-size: 11px;
  font-weight: 300;
  opacity: 0.9;
  cursor: pointer;
}