.tableRepairHistory{
  width: 100%;
  float: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px;
  padding-bottom: 0px;
}
.RHtab{
  width: 60%;
  float: left;
  background-color: #fff;
  border: 1px solid #0F5E91;
  border-radius: 8px;
  padding: 8px;
  display: flex;
align-items: center;
margin-bottom: 12px;
}
.rhIcon{
  width: 10%;
  float: left;
  
}
.rhName{
  width: 80%;
  float: left;
  padding: 0 15px;
  font-weight: 500;
  font-size: 14px;
  color: #757575;
}
.rhDownload{
  width: 10%;
  float: left;
 
}
.rhfile{
  width: 45px;
}
.rhdowImg{
  width: 45px;
  cursor: pointer;
}