
.tableSearch{
  box-sizing: border-box;

/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
padding: 3px 14px;
gap: 8px;

width: 296px;
height: 40px;

background: #FFFFFF;

/* grey/10 */
border: 1px solid #F5F5F5;
border-radius: 8px;
  margin: 0 5px;
}

.tableSearchInput{
  border: 0;
}
*:focus {
  outline: none;
}