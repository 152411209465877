.login-side {
   
    align-items: center;
    justify-content: space-between;
    width: 447px;
    margin: 0px auto;
    padding: 10px;
    margin-top: 90px;
    text-align: center;
    
    
  }
  .login-side .logo{
    width: 150px;
}
  .login-container {    
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 32px 24px;
    gap: 10px;
    width: 447px;
    height: 448px;
    margin: 0px auto;
    background: #FFFFFF;    
    box-shadow: 0px 4px 12px rgba(15, 15, 15, 0.08);
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
.login-title{ 
    height: 44px;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #06263A;
}
.login-text{
    height: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    padding-bottom: 35px;
    padding-top: 10px;
}

.loginfull{
    float: left;
    width: 100%;
    padding: 10px 0px;
}
.loginremb{
    float: left;
    width: 65%;
}
.loginforg{
    float: left;
    width: 35%;
    padding-top: 10px;
text-align: right;
}
.loginforg a {    
    color:#0C4B74;
    text-decoration: none;
}
.loginbtn{
    width: 100%;
    background: #0F5E91;
    padding: 10px 18px;
    height: 44px;
    border: 1px solid #0F5E91;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;


}
.loginInputField{
    padding: 10px 0px;
}
.loginTurckImage {display:flex;}
.loginTurck{
    
    position: absolute;



width: auto;
height: 100%;
object-fit: contain;
}