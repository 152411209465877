.noFileIcon{
    float: left;
    width: 100%;
    text-align: center;
    padding: 100px 0 40px 0px;
}
.noFileImg{
    width: 150px;
}
.noFileIconText{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #101828;   
    
}
.DPStatus{
    float: right;
    display: flex;
    padding: 0 15px;
    margin-top: 13px;
    font-weight: 600;
}
.DPStatus p{
    
    font-weight: 400;
}