.thadder{
  width: 100%;
  float: left;
  background: #0F5E91;
  color: #fff;
  padding: 0px 20px 20px 20px;
}
.hdrIcon{
  width: 10%;
float: left;
}
.thdtImg{
  width: 40px;
}
.hdrtitle{
  width: 60%;
  float: left;
  font-weight: 500;
  font-size: 20px;
}
.hdrdate{
  width: 30%;
  float: left;
  text-align: right;
  font-weight: 500;
font-size: 14px;
padding-top: 10px;
}
.tmiddel{
  width: 100%;
  float: left;
  background: #fff;
}
.textline{
  width: 100%;
  float: left;
  padding: 20px 20px;
  border-bottom: 1px solid #F5F5F5;
  font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #333333;
}
.textlinel{
  float: left;
  
}
.textlinev{
  float: right;
  color: #545454;
  
}

.tfooter{
  width: 100%;
  float: left;
  background-color: #fff;
padding-bottom: 30px;
padding-top: 10px;
}
.tfooter50{
  width: 50%;
  float: left;
  text-align: center;
}
.dImg{
  width: 20px;
}
.bigbtn{  
  width: 240px;
}

#TransactionModel .lightBtn{
  color: #757575 !important;
}
#TransactionModel .MuiPaper-root{
  background: #0F5E91;
  color: #fff;
}

#TransactionModel .MuiButtonBase-root{
  color: #fff;
}

#TransactionModel .MuiDialogTitle-root + .MuiDialogContent-root {
  padding: 0;
}