.succDiv{
  width: 100%;
  float: left;
}
.imgCant{
  width: 100%;
  float: left;
  text-align: center;
  padding-bottom: 20px;
}
.imgCant .succImg{
  width:75px;
}
.headingText{
  width: 100%;
  float: left;
  text-align: center;
  font-weight: 500;
font-size: 18px;
line-height: 28px;
color: #333333;
padding-bottom: 8px;

}
.lineText{
  width: 100%;
  float: left;
  text-align: center;
  font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #667085;
padding-bottom: 50px;


}
.botambtn{
  width: 100%;
  float: left;
  text-align: center;
}
