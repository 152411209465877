@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .provider-profile-div {
    @apply bg-white py-6 px-6 shadow-provider-proifle rounded-lg;
  }

  .box {
    @apply border border-solid border-black;
  }
}

::-webkit-scrollbar {
  width: 6px;
  scrollbar-width: thin;
  height: 1px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 6px;
  scrollbar-width: thin;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;

  border-radius: 20px;
  width: 6px;
  background-clip: content-box;
  height: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
  scrollbar-width: thin;
}
.table-full{
  overflow: auto;
  width: 100%;
  display: inline-block;
}
.table{
  width: 100%;
}
.tables-empty{
  padding: 30px 0;
  text-align: center;
}
.table-border{
  border: 1px solid #E3E3E3;
  border-radius: 8px;
  border-spacing: 0;
  border-collapse: collapse;
 
}
.table-border tr{
  border-bottom: 1px solid #F5F5F5;
}
.table-th-plr{
  padding: 10px 10px;
}
.table-th-blc{
background-color: #f5f5f5;
font-size: 12px;
font-weight: 500;
color: #333;
line-height: 18px;
}
.tableActionsBtn{
  border: 0;
  background: transparent;
}
.table-tr-blc{ 
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #757575;
  }
.font10{
  font-size: 10px;
}
.fw700{
  font-weight: 700;
}
.fw600{
  font-weight: 600;
}
.fw400{
  font-weight: 400;
}
.maincolor{
  color: #0F5E91;
}
.greycolor{
  color: #545454;
}
.grey60color{
  color: #757575;
}

.underline{
  text-decoration: underline;
  text-underline-offset: 7px;
}
.cursorpointer{
  cursor: pointer;
}
.REVIEW_PENDING{
  color: #CC9B00;
}
.INSPECTION_FEE_PENDING{
  color: #545454;
}
.INSPECTION_PENDING{
  color: #545454;
}
.PRODUCT_REJECTED{
  color: #DC2804;
}
.PRODUCT_LISTED{
  color: #05945B;
}
.PRODUCT_SOLD{
  color: #027A48;
}
.PRODUCT_ON_HOLD{
  color: #CC9B00;
}
.REMOVAL_REQUESTED{
  color: #CC9B00;
}
.PRODUCT_REMOVED{
  color: #CC9B00;

}
.yellowColour{
  color: #CC9B00;
}
.greenColour{
  color: #027A48;
}
.grayColour{
  color: #545454;
}
.redColour{
  color: #DC2804;
}
.grayLabel{
  padding: 3px 16px;
  color: #757575;
  background-color: #F5F5F5;
  border-radius: 16px;
  
}
.greenLabel{
  padding: 3px 16px;
  color: #05945B;
  background-color: #EDF7F2;
  border-radius: 16px;
}
.yellowLabel{
  padding: 3px 16px;
  color: #CC9B00;
  background-color: #FFF9E5;
  border-radius: 16px;
}
.table-options-section .rs-picker-toggle {
  width: 0;
  height: 0;
  padding: 0 !important;
  border: 0 !important;
  
}
.table-options-section .rs-picker-toggle-active{
 /* -webkit-box-shadow: 0 !important;*/
}
.table-options-section .rs-picker-toggle-active, .rs-picker.rs-picker-focused{
  -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0);
  box-shadow: 0 0 0 3px rgba(52, 152, 255, 0);

}
.table-options-section .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #fff !important;
}
#table-filter .MuiList-padding{
  padding: 0 !important;
}
#table-dp-filter .MuiList-padding{
  padding: 0 !important;
}

#table-filter .MuiAccordion-root {
  background-color: transparent;
  position:unset;
  clear: both;
  display: contents;
}
.tableFilter{
  width: 375px;
}
.tableFilterMaxH{
  width: 375px;
  max-height: 450px;
  display: flow-root;
}
.tableFilterTop{
  width: 100%;
  float: left;
  height: 48px;  
  background-color: #0F5E91;
  color: #fff;
  padding: 10px;
}
.tableFilterTopText{
  float: left;
  font-weight: 500;
font-size: 18px;
line-height: 28px;
}
.tableFilterIcon{
  float: right;
  cursor: pointer;
}
.tableFilterBottom{
  height: 70px; 
  width: 100%;
  float: left;
  padding: 15px 0;
}
.tableFilterBottomL{
  float: left;
  width: 50%;
  text-align: center;
  
}
.tableFilterBottomR{
  float: left;
  width: 50%;
  text-align: center;  
}
.tableFilterCBtn{
  background-color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #344054;
  padding: 10px 16px;  
  text-transform: none;
  height: 44px;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.tableFilterCBtn:hover{
  background-color: #f0f2f4;
  border: 1px solid #D0D5DD;
}
.tableFilterPBtn{
  background-color: #0F5E91;
  font-weight: 600;
font-size: 14px;
line-height: 18px;
color: #ffffff;
padding: 10px 16px;
border: 1px solid #0C4B74;
border-radius: 8px;
text-transform: none;
height: 44px;
}

.tableFilterPBtn:hover{
  background-color: #0F5E91;
}
.tableFilter .css-13sxquz-MuiFormControl-root{
  width: 100%;
}
.tableFilterText{
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.tableFilter .css-1n756jj-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-1n756jj-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #0F5E91;
}
.tableSMRType{
  float: left;
  width: 100%;
}
.tableSMRTypeLeft{
  float: left;
}
.tableSMRTypeRight{
  float: right;
}
.tableSMRType .smrTypeActive{
  color: #ffffff;
  background-color: #0F5E91;
}

.tableSMRType #SMR{
  border: 1px solid #E3E3E3;
  border-radius: 8px 0px 0px 8px;
  padding: 15px 20px;
}
.tableSMRType #NON_SMR{
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #E3E3E3;
  border-radius: 0px 8px 8px 0px;
  padding: 15px 20px;
}
.yearRange{
  padding: 20px;
  clear: both;
  font-weight: 500;
font-size: 16px;
}
.yearRangeSelect{
  width: 140px;
  padding: 0;
}
.statusSelectFiled{
 float: left;
 cursor: pointer;
}
.statusSelectFiledIcon{
  float: right;
  padding-top: 3px;
  cursor: pointer;
}
.statusBox{
  display: inline-block;
background-color: #fff !important;
width: 220px;
padding: 8px;

border: 1px solid #F5F5F5;
border-radius: 5px;
height: 36px;
margin: 0 10px;
}
.statusBoxLabel{
  float: left;
  padding: 8px;
}
.statusOpactionbox{
  width: auto;
}
.statusOptList{
  padding: 10px 15px;
  cursor: pointer;
}
.statusOptList a:hover{  
  text-decoration: none !important;
}
.profileLink{
  display: flex;
  color: #0F5E91;
  font-size: 14px;
  font-weight: 500;
}
.profileLink .text{ 
  padding-left: 10px;
}
.logoutLink{
  display: flex;
  color: #DC2804;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.logoutLink .text{
  margin-top: -2px;
  padding-left: 10px;
}
.disList{
  display: inline list-item;
}