.imgDiv{  
  float: left;
}
.NameDiv{  
  float: left;
  padding-left: 15px;
}
.UserLable{
  float: left;
  width: 100%;
  font-weight: 500;
font-size: 15px;
line-height: 20px;
color: #333333;
}
.UserText{
  float: left;
  width: 100%;
  font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #545454;

}
.uTab{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;  
  width: 200px;
  border: 1px solid #F5F5F5;  
  float: left;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}
.uTabF{ 
  border-radius: 8px 0px 0px 8px; 
}
.uTabL{ 
  border-radius: 0px 8px 8px 0px; 
}
.uTabActive{
  background: #0F5E91;
  color: #ffff;
}
.uTabText{

}
.uTabCount{
  padding: 1px 10px;
  color: #757575;
  background-color: #F5F5F5;
  border-radius: 16px;
  margin-left: 10px;
}
.full{
  width: 100%;
  float: left;
}
.mtm15{
  margin-top: -15px;
}
.docheading{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.textRight{
  text-align: right;
}
