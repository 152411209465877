.contpad{
    padding: 10px !important;
    box-shadow: 0px 2px 8px rgba(15, 15, 15, 0.06);
    border-radius: 10px;
}
.conttablepad{
    padding: 0px !important;
    box-shadow: 0px 2px 8px rgba(15, 15, 15, 0.06);
    border-radius: 10px;
}
.wgtitle{
    float: left;
    width: 100%;
    background: #0F5E91;
    border-radius: 6px;
    color: #fff;
    padding: 10px 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    cursor: pointer;
}
.wgbody{
    width: 100%;
    float: left;
    padding: 10px;
    min-height: 183px;
}
.wgtotal{
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    margin-bottom: 15px;
}
.tlright{
    text-align: right;
    float: right;
    flex: fit-content;
    color: #333333;
}
.wgline{
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0F5E91;
    margin-bottom: 15px;
}
.wgBgline{
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #0F5E91;
    margin-bottom: 6px;
    background: #F5F5F5;
    border-radius: 20px;
    padding: 6px 6px;
}
.wgBgtextL{
    width: 50%;
    display: flex;
    padding-right: 30px;
}
.wgBgtextR{
    width: 50%;
    display: flex;
    padding-left: 30px;
}
.wgtabletitle{
    display: flex;
    align-items: center;
    padding: 20px;
}
.wgtabletitleTxt{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #333333;   
}
.wgtabletitleBtn{
    text-align: right;
    float: right;
    flex: fit-content;
}
.fulltable{
    width: 100%;
    float: left;
}
.wgSubTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #667085;
}