.noFileIcon{
    float: left;
    width: 100%;
    text-align: center;
    padding: 100px 0 40px 0px;
}
.noFileImg{
    width: 150px;
}
.noFileIconText{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #101828;   
    
}