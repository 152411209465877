.datePickerInlineEdit {
  width: 85%;
  padding: 0;
  position: relative;
  background: #f3f6f9;
  border-radius: 6px;
  top: 3px;
  input {
    margin: auto;
    padding: 0.73rem 0 0.73rem 0.5rem;
  }
}

.calendarCell{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  border-radius: 8px;
  margin: 0 5px;
  cursor: pointer;
}
.calendarCell img{
  width: 18px;
  z-index: 5;
}

.buttonCell{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  border-radius: 8px;
  margin: 0 5px;
  cursor: pointer;
}
.buttonCell img{
  width: 24px;
}
.bellCell{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  border-radius: 8px;
  margin: 0 5px;
  cursor: pointer;
}
.bellCell img{
  width: 18px;
}

.sortedIcon{
  padding-left: 10px;
}