.listingImg{
  border-radius: 8px;
  max-width: 250px;
}
.lableText{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}
.valueText{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #545454;
}