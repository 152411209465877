.fPhoto{
  width: 100%;
  float: left;
}
.fimgTabName{
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
}
.fimgCant{
  width: 100%;
  float: left;
  background-color: #fff; 
  height: 150px;  
  align-items: center;
  overflow:hidden;
  border-radius: 8px;
  border: 1px solid #BBDBF0;
}
.fImg{ 
  margin: auto;
  display: block;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
}

