.listingPhoto{
  width: 100%;
  float: left;
}
.imgTabName{
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3px;
}
.imgCant{
  width: 100%;
  float: left;
  background-color: #fff; 
  height: 150px;  
  align-items: center;
  overflow:hidden;
  border-radius: 8px;
  background-repeat: no-repeat;
  position: relative;
  border: 1px solid #e6e6e6;
  background-color: #f2f2f2;
}
.imgCant .fileImg{
  min-width:100%;
}
.imgCheckbox{  
  float: left;
  color: #fff;
  background-color: #FFC200;
  border-radius: 8px;
  opacity: 0.8;
  margin: 5px;
  padding-right: 3px;
}
.imgCheckboxnotBG{  
  float: left;
  color: #fff; 
  border-radius: 8px;
  opacity: 0.8;
  margin: 5px;
  padding-right: 3px;
}

.imgDelete{
  float: right;
  text-align: center;
  padding: 5px; 
  border-radius: 8px; 
  margin: 5px;
  cursor: pointer;
}
.deleteImg{
  width: 20px; 
}
.imgCantBo{
  width: 100%;
  float: left;
  background-color: #fff; 
  height: 150px;
  display: flex; 
  align-items: center;
  border: 1px dashed #EDEDED;
  border-radius: 8px;
}
.imgCantBo span{
  width: 100%;
}
.fileIcon{
  width: 100%;
  float: left;
  text-align: center;
  font-weight: 400;
font-size: 14px;
}
.fileIcon .fileImg{
  width: 50px;
}
.fileIconText{
  line-height: 40px;
}
.VideoCant{
  width: 100%;
  float: left;
  background-color: #fff; 
  height: 90px;
  display: flex; 
  align-items: center;
  border: 1px solid #FFC200;
  border-radius: 8px;
}
.PdfCant{
  width: 100%;
  float: left;
  background-color: #fff; 
  height: 90px;
  display: flex; 
  align-items: center;
  border: 1px solid #EDEDED;
  border-radius: 8px;
}
.lodingBarV{
  width: 100%;
  height: 8px;
  background-color: #FFC200;
  border-radius: 4px;
  display: block;
  margin-top: 12px;
}
.lodingBarF{
  width: 100%;
  height: 8px;
  background-color: #0f5e91;
  border-radius: 4px;
  display: block;
  margin-top: 12px;
}
.fs12{
  margin-top: 10px;
  font-size: 12px;
  display: block;
}
.VideoIcon{
  float: left;
  width: 10%;
  padding-left: 5px;
}
.VideoName{
  float: left;
  width: 76%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  overflow: hidden;
  padding: 0 10px;
  cursor: pointer;  
}
.VideoDelete{
  float: left;
  width: 10%;
  text-align: right;
padding-right: 10px;
}
.deleteVideoImg{
  width: 15px;
  cursor: pointer;
}
.imgAction{
  position: absolute;
  top: 0;
  width: 100%;
}
.imgListinDiv{
  display: block;
  cursor: pointer;
}
.listImg{
  max-width: 100%;
  max-height: 150px;
  min-height: 150px;
  min-width: 100%;
  object-fit: contain;
}
.fPhoto{
  width: 100%;
  float: left;
}
.fimgTabName{
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
}
.fImg{ 
  margin: auto;
  display: block;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
}
.imgCheckboxpad{
  padding: 0px !important;
}