.login-side {   
    align-items: center;
    justify-content: space-between;
    width: 447px;
    margin: 0px auto;
    padding: 10px;
    margin-top: 90px;
    text-align: center;   
    
  }
  .login-side .logo{
    width: 150px;
}
  .fp-container {    
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 32px 24px;
    gap: 10px;
    width: 447px;    
    margin: 0px auto;
    background: #FFFFFF;    
    box-shadow: 0px 4px 12px rgba(15, 15, 15, 0.08);
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
.fpIcon{
    text-align: center;
    margin-bottom: 10px;
  }
.pKeyIcon{
    width: 50px;
  }
.fp-title{
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #06263A;
    text-align: center;
}
.fp-text{    
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    padding-bottom: 35px;
    padding-top: 10px;
    text-align: center;
}
.fp-BackToLogin{
    text-align: center;
    width: 100%;
    float: left;
    padding-top: 30px;
    font-style: normal;
font-weight: 500;
font-size: 14px;

}
.BackToLogin{
    color: #333333;
}
.fp-BackToLogin a:hover{
    color: #333333;
    text-decoration: none;
}
.fp-icon{    
    font-size: 18px;
}
.BackToLogin span{
    vertical-align: super;
}
.loginfull{
    float: left;
    width: 100%;
    padding: 10px 0px;
}

.loginbtn{
    width: 100%;
    background: #0F5E91;
    padding: 10px 18px;
    height: 44px;
    border: 1px solid #0F5E91;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;

}
.loginInputField{
    padding: 10px 0px;
}
.loginTurckImage {display:flex;}
.loginTurck{    
    position: absolute;
    width: auto;
    height: 100%;
    object-fit: contain;
}
.otpinput{
    text-align: center;
font-size: 18px;
display: flow-root !important;
}
.otpinput input{
    width: 60px !important;
    height: 60px !important;
    font-size: 30px;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
}

.resentdiv{
    display: flex;
    color: #757575;
    text-align: center;
    font-size: 16px;
}
.resentSec{
    color: #0F5E91;
    font-size: 16px;
    padding-left: 10px;
}

.resentSec button{    
    margin-left: 10px;
    color: #000;
    border-radius: 8px;
    padding: 3px 10px;
    font-size: 12px;
}